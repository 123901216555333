import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import AboutUs from "./AboutUs";
import { NavbarDefault } from "../components/Navbar";
import { GalleryWithTab } from "./Gallery";
import { FooterWithSocialLinks } from "./Footer";
import ComingSoon from "./ComingSoon";
// import About from './components/About';
// import Contact from './components/Contact';
// import NotFound from './components/NotFound';

const AppRouter = () => {
  return (
    <Router>
      {false ? (
        <ComingSoon />
      ) : (
        <>
          <NavbarDefault />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/gallery" element={<GalleryWithTab />} />
          </Routes>
          <FooterWithSocialLinks />
        </>
      )}
    </Router>
  );
};

export default AppRouter;
