import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
  } from "@material-tailwind/react";
   
  export function GalleryWithTab() {
    const data = [
      {
        label: "INDOOR",
        value: "rooms",
        images: [
          {
            imageLink: "/assets/images/gallery/rooms/r1.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r2.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r3.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r4.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r5.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r6.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r7.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r8.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r9.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r10.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r11.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r12.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r13.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r14.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r15.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r16.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r17.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r18.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r19.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r20.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r21.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r22.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r23.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r24.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r25.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r26.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r27.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r28.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r29.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r30.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r31.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r32.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r33.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r34.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r35.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r36.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r37.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r38.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r39.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r40.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r41.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r42.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r43.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r44.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r45.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r46.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/r47.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr1.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr2.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr3.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr4.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr5.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr6.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr7.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr8.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr9.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr10.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr11.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr12.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr13.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr14.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr15.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr16.jpg",
          },
          {
            imageLink: "/assets/images/gallery/rooms/wr17.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Celebration/wr18.jpg",
          },

          
        ],
        
      },
      {
        label: "OUTDOOR",
        value: "outdoor",
        images: [
          {
            imageLink: "/assets/images/gallery/Scenere/s1.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s2.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s3.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s4.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s5.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s6.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s7.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s8.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s9.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s10.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s11.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s12.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s13.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s14.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s15.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s16.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s17.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s18.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s19.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s20.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s21.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s22.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s23.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s24.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s25.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s26.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s27.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s28.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s29.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s30.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Scenere/s31.jpg",
          },
        
        ],
      },
      {
        label: "WASHROOM",
        value: "washrooms",
        images: [
          {
            imageLink: "/assets/images/gallery/washroom/b1.jpg",
          },
          {
            imageLink: "/assets/images/gallery/washroom/b2.jpg",
          },
          {
            imageLink: "/assets/images/gallery/washroom/b3.jpg",
          },
          {
            imageLink: "/assets/images/gallery/washroom/b4.jpg",
          },
          {
            imageLink: "/assets/images/gallery/washroom/b5.jpg",
          },
          {
            imageLink: "/assets/images/gallery/washroom/b6.jpg",
          },
          {
            imageLink: "/assets/images/gallery/washroom/b7.jpg",
          },
        ],
      },
      {
        label: "DINING",
        value: "dining",
        images: [
          {
            imageLink: "/assets/images/gallery/Dining/d1.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d2.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d3.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d4.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d5.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d6.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d7.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d8.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d9.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d10.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d11.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d12.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d13.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d14.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d15.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d16.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d17.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d18.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d19.jpg",
          },
          {
            imageLink: "/assets/images/gallery/Dining/d20.jpg",
          },
        ],
        
      },
    ];
   
    return (
      <Tabs value="rooms" className="mt-8">
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab className="text-sm md:text-normal" key={value} value={value}>
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="grid grid-cols-1 gap-4 ">
          {data.map(({ value, images }) => (
            <TabPanel
              className="grid grid-cols-1 gap-4 md:grid-cols-2"
              key={value}
              value={value}
            >
              {images?.map(({ imageLink }, index) => (
                <div key={index}>
                  <img
                    className="h-full w-full max-w-full rounded-lg object-cover object-center"
                    src={imageLink}
                    alt={value+imageLink}
                  />
                </div>
              ))}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    );
  }