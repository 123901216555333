import { Carousel } from "@material-tailwind/react";
import React from "react";
import ReactPlayer from "react-player";
import './slider.css';

const carouselData = [
  {
    image: "/assets/images/slider/1.jpg",
    title: "Sierra In",
    description:
      "By Namana Valley",
  }

];

const VideoBanner = () => {
  return (
    <Carousel prevArrow={false} nextArrow={false} navigation={false} >
      {carouselData.map((item, index) => (
        <div key={index} className="container mx-auto relative h-[90vh] w-full">
           <ReactPlayer
              url={"/assets/video/desktop.mp4"}
              playing={true}
              config={{ file: { attributes: { controlsList: 'nodownload', onContextMenu: (e) => e.preventDefault() } } }}
              muted={false}
              controls={true}
              playsinline={true}
              volume={0.3}
              loop={true}
              width="100%"
              height="100%"
              className="banner-video z-50"
            />
          {/* <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/60">
            <div className="w-3/4 text-center md:w-2/4">
              <Typography
                variant="h1"
                color="white"
                className="mb-4 text-3xl md:text-5xl lg:text-6xl"
              >
                {item.title}
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className="mb-12 opacity-80"
              >
                {item.description}
              </Typography>
            </div>
          </div> */}
        </div>
      ))}
    </Carousel>
  );
};

export default VideoBanner;
