import React from "react";
import Slider from "../../components/Home/Slider";
import { BlogCard } from "../../components/Home/Featured";
import { AccordionFaqs } from "../../components/Faqs";
import GettingIn from "../../components/Home/GettingIn";
import { DescriptionCard } from "../../components/Home/ShortDescription";
import { Places } from "../../components/Home/Places";
import VideoBanner from "../../components/Home/VideoBanner";
import Title from "../../components/Common/Title";
import { Fade, Zoom } from "react-awesome-reveal";
import Testimonial from "../../components/Home/Testonomials";
import {
  Element,
} from "react-scroll";
import Services from "../../components/Home/Services";

const amenitiesData = [
  {
    imageUrl: "/assets/images/featured/room_new.jpg",
    title: "Elegant Sanctuaries",
    description:
      "Unwind in our tastefully designed rooms, blending comfort and sophistication for a perfect retreat.",
  },
  {
    imageUrl: "/assets/images/featured/celebrate.jpg",
    title: "Celebration Spaces",
    description:
      "Mark life's milestones with us, offering tailored settings and impeccable service for your special occasions.",
  },
  {
    imageUrl: "/assets/images/featured/balcony.jpg",
    title: "Mountain Vistas from Balcony",
    description:
      "Enjoy breathtaking mountain views right from your private balcony, creating lasting memories.",
  },
  {
    imageUrl: "/assets/images/featured/dining_new.jpg",
    title: "Dining Delights",
    description:
      "Savor exquisite culinary experiences in our refined dining area, where every meal is a journey of flavors.",
  },
  {
    imageUrl: "/assets/images/featured/bath_new.jpg",
    title: "Luxurious Bath Retreats",
    description:
      "Indulge in opulence with our well-appointed bathrooms, designed for relaxation and rejuvenation.",
  },
  {
    imageUrl: "/assets/images/featured/fire.jpg",
    title: "Bonfire Bliss",
    description:
      "Experience camaraderie around the bonfire, creating memorable evenings under the starlit Himalayan sky.",
  },
];

function Home() {
  return (
    <>
      <Slider />
      <div className="container py-5 px-2 lg:px-0 mx-auto mt-10">
        <DescriptionCard />
        
        <div className="py-6 ">
          <Element name="Aminities" className="element">
            <Title container={false} color={"blue-gray"} title={"Amenities"} />
          </Element>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 ">
            {amenitiesData.map((amenity, index) => (
              <Fade key={index} triggerOnce cascade>
                <BlogCard
                  imageUrl={amenity.imageUrl}
                  title={amenity.title}
                  description={amenity.description}
                />
              </Fade>
            ))}
          </div>
        </div>
      </div>

      <div className="py-6 px-3 lg:px-0  bg-[#29343d] ">
        <Element name="Getting In" className="element">
          <Title container={true} color={"white"} title={"Getting In"} />
        </Element>
        <Fade cascade triggerOnce>
          <GettingIn />
        </Fade>
      </div>

      <div className="py-6 px-4 lg:px-0">
        <Fade triggerOnce cascade>
          <Element name="Places to visit" className="element">
            <Title
              container={true}
              color={"blue-gray"}
              title={"Places to visit near Sierra In"}
            />
          </Element>
          <Places />
        </Fade>
      </div>
      <div className="bg-[#29343d] py-6 px-2 lg:px-0">
        <Zoom triggerOnce>
          <Element name="Walkover" >
            <Title
              container={true}
              color={"white"}
              title={"Know more with this video"}
            />
          </Element>
          <VideoBanner />
        </Zoom>
      </div>
      <div className="py-6 px-4 lg:px-0">
        <Fade triggerOnce cascade>
          <Element name="Reviews" >
            <Title
              container={true}
              color={"blue-gray"}
              title={"What people are saying"}
            />
          </Element>
          <Testimonial />
        </Fade>
      </div>

      <div className="py-6 px-4 lg:px-0">
        <Fade triggerOnce cascade>
          <Element name="Services" >
            <Title
              container={true}
              color={"blue-gray"}
              title={"Services"}
            />
          </Element>
          <Services />
        </Fade>
      </div>
      
      <div className="container px-5 lg:px-0 mx-auto py-6">
        <Fade cascade triggerOnce>
          <Element name="FAQs">
            <Title container={false} color={"blue-gray"} title={"FAQs"} />
          </Element>
          <AccordionFaqs />
        </Fade>
      </div>
    </>
  );
}

export default Home;
