import { Typography } from "@material-tailwind/react";
import React from "react";

function ComingSoon() {
  return (
    <div className="">
      <div className="h-[100vh] grid place-content-center justify-center">
        <Typography variant="h1" className="text-gray-800">Sierra In<span className="text-sm">by Namana Valley</span></Typography>
        <Typography variant="base" className="text-center">Site is under development</Typography>
      </div>
    </div>
  );
}

export default ComingSoon;
