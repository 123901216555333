import { Button, Carousel, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router";

const carouselData = [
  {
    image: "/assets/images/slider/1.jpg",
    title: "Luxury Sanctuaries: Explore Our Chic Retreats",
    description:
      "Indulge in opulence as you discover our thoughtfully designed rooms – a perfect blend of comfort and style, creating an oasis for your relaxation and rejuvenation.",
  },
  {
    image: "/assets/images/slider/2.jpg",
    title: "Nature's Majesty: Immersed in Mountain Bliss",
    description:
      "Step outside and surround yourself with the awe-inspiring beauty of nature. Our location is a gateway to mountainous wonders, a perfect blend of modern comfort and the untamed splendor of the great outdoors.",
  },
  {
    image: "/assets/images/slider/3.jpg",
    title: "Sunset Splendor: Unforgettable Views at Dusk",
    description:
      "Unwind with our prime location offering front-row seats to nature's grand finale. Experience the magic of breathtaking sunsets, painting the sky with hues of warmth and wonder.",
  },
  {
    image: "/assets/images/slider/7.jpg",
    title: "Harmony in Culinary Delights: Indulge Amidst Nature's Bounty",
    description:
      "Indulge in culinary delights amidst untamed mountain beauty. Modern comfort meets wilderness charm for an unforgettable dining experience",
  },
  {
    image: "/assets/images/slider/4.jpg",
    title: "Skyline Serenity: Your Exclusive Balcony Escape",
    description:
      "Elevate your experience with a private balcony boasting panoramic views. Immerse yourself in tranquility and nature's beauty, capturing moments that linger in the heart.",
  },
];

const Slider = () => {
  const navigate = useNavigate();
  return (
    <Carousel autoplay={5000} loop={true}>
      {carouselData.map((item, index) => (
        <div key={index} className="relative h-[90vh] w-full">
          <img
            src={item.image}
            alt={`${item.title}`}
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/60">
            <div className="w-3/4 text-center md:w-2/4">
              <Typography
                variant="h1"
                color="white"
                className="mb-4 text-3xl md:text-4xl lg:text-5xl"
              >
                {item.title}
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className="mb-12 opacity-80"
              >
                {item.description}
              </Typography>
              {index === 0 ? (
                <div className="flex justify-center gap-2">
                  {/* <Button size="lg" color="white">
                  Explore
                </Button> */}
                  <Button
                    size="lg"
                    color="white"
                    onClick={() => navigate("/gallery")}
                  >
                    Gallery
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default Slider;
