import React from "react";
import { FaAccessibleIcon,FaHandHoldingHeart } from "react-icons/fa";
import { IoWifiOutline } from "react-icons/io5";
import { MdDryCleaning,MdOutlineFamilyRestroom  } from "react-icons/md";
import { BiDish } from "react-icons/bi";
import { LuParkingCircle } from "react-icons/lu";
function Services() {
  const iconSize=30
  const iconcolor="#263238"
  const cards = [
    {
      service: "Accessibility",
      icon: <FaAccessibleIcon size={iconSize} color={iconcolor}/>,
    },
    {
      service: "Free WIFI",
      icon: <IoWifiOutline size={iconSize} color={iconcolor} />,
    },
    {
      service: "Breakfast buffet",
      icon: <BiDish size={iconSize} color={iconcolor} />,
    },
    {
      service: "Room service",
      icon: <FaHandHoldingHeart size={iconSize} color={iconcolor} />,
    },
    {
      service: "Dry cleaning",
      icon: <MdDryCleaning size={iconSize} color={iconcolor} />,
    },
    {
      service: "Parking",
      icon: <LuParkingCircle size={iconSize} color={iconcolor} />,
    },
    {
      service: "Family program",
      icon: <MdOutlineFamilyRestroom size={iconSize} color={iconcolor} />,
    },
  ];

  return (
    <div className="bg-[#F7F7FA]">
      <div className="p-3 lg:p-0 container mx-auto">
        <button
          className="px-2 rounded-full text-sm "
          style={{ backgroundColor: "#E0F8DE" }}
        >
          Services we offer
        </button>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-16">
          {cards.map((card, index) => (
            <div
              key={index}
              className="h-full bg-white-800 shadow-md bg-opacity-75 p-6 rounded-lg overflow-hidden text-center relative grid grid-cols-2 "
            >
              <div>{card.icon}</div>
              <div className="text-left">{card.service}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
