import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import { useState } from "react";
const placesData = [
  {
    name: "Bathu Ki Ladi",
    distance: "69 km",
    image: "/assets/images/places/bathu.jpg",
    description:
      "Discover the submerged beauty of Bathu ki Ladi Temple in Jawali, where the main shrine honors goddess Parvati and Lord Shiva. These temples, submerged in the tranquil waters of Maharana Pratap Sagar, offer a unique glimpse into ancient history.",
  },
  {
    name: "Masroor Rock Cut Temple",
    distance: "44 km",
    image: "/assets/images/places/masroor.jpg",
    description:
      "Journey back in time at the Masroor Rock Cut Temple, an 8th-century marvel nestled in the Kangra Valley. Marvel at the intricately carved rock temples facing the majestic Dhauladhar range",
  },
  {
    name: "Norbulinka Monestry",
    distance: "3 km",
    image: "/assets/images/places/norbulinka.jpg",
    description:
      "Explore the Norbulingka Institute and immerse yourself in the rich heritage of Tibetan arts and crafts, where skilled artisans pass down their ancestral knowledge. Wander through the picturesque campus and artisanal shops showcasing exquisite Tibetan wood carvings, paintings, and weavings.",
  },
  {
    name: "Kharota Trek",
    distance: "3 km",
    image: "/assets/images/places/kharota.webp",
    description:
      "Embark on the Kharota Trek and behold awe-inspiring vistas of the snow-capped Dhauladhar mountains from Dharamsala's enchanting Kharota area.",
  },
  {
    name: "Indru Nag",
    distance: "8 km",
    image: "/assets/images/places/indru.jpg",
    description:
      "Explore the serene Indru Nag Temple, dedicated to Indru Devta, associated with rain, nestled in Dharamshala's mountains. This sacred sanctuary offers solace amidst stunning natural surroundings, enveloped by prayer flags' whispers and the Himalayas' gentle breeze.",
  },
  {
    name: "Aghanjar Mahadev Temple",
    distance: "2 km",
    image: "/assets/images/places/temple.jpg",
    description:
      "Nestled in the serene foothills of the Dhauladhar mountain range lies the ancient Aghanjar Mahadev Temple, a sanctuary dedicated to Shiva. Here, amidst lush mountain forests and cascading waterfalls, find solace and tranquility.",
  },
  {
    name: "War Memorial",
    distance: "8 km",
    image: "/assets/images/places/war-memorial.jpg",
    description:
      "Discover the poignant War Memorial Museum, a dignified tribute to Indian soldiers who bravely fought in the 20th century. Wander through the peaceful forest setting, explore the layered gardens, and savor the ambiance at the on-site café.",
  },
  {
    name: "Kangra Art Museum",
    distance: "22 km",
    image: "/assets/images/places/kangra-art.jpg",
    description:
      "Step into the Kangra Art Museum in Kotwali Bazaar and delve into the vibrant cultural heritage of Kangra and Tibet. Marvel at the exquisite miniature paintings from the Katoch dynasty, a testament to the region's artistic legacy.",
  },
  {
    name: "St. John’s Church",
    distance: "14 km",
    image: "/assets/images/places/cruch.jpg",
    description:
      "Experience the timeless beauty of St. John’s Church, where neo-Gothic architecture meets serene surroundings. Admire the intricate stained-glass window and stroll through the verdant churchyard nestled amid rolling hills.",
  },



  {
    name: "Bagsu Nag",
    distance: "11 km",
    image: "/assets/images/places/bagsu.jpg",
    description:
      "Nestled in the serene beauty of Dharamshala, Bagsu Nag waterfall enchants with its cascading waters. A natural wonder, it offers a tranquil escape amidst lush greenery. Visitors revel in its refreshing spray, finding solace in its picturesque surroundings.",
  },
  {
    name: "Dal Lake",
    distance: "15 km",
    image: "/assets/images/places/dallake.jpeg",
    description:
      "Dal Lake in Dharamshala beckons with its tranquil waters and breathtaking views of the Himalayas. Surrounded by verdant hills, it invites peaceful strolls along its shores, offering moments of reflection and serenity amidst nature's embrace.",
  },
  {
    name: "Tea Garden",
    distance: "5 km",
    image: "/assets/images/places/teagarden.jpg",
    description:
      "Dharamshala's tea gardens sprawl in picturesque elegance, offering a tranquil retreat amidst rolling hills. Visitors wander through lush fields, savoring the aroma of freshly brewed tea. The vibrant greenery and scenic beauty create a serene escape for all who visit.",
  },
  {
    name: "Triund",
    distance: "13 km",
    image: "/assets/images/places/triud.jpg",
    description:
      "Perched atop Dharamshala, Triund unveils panoramic vistas of the Dhauladhar range. Adventurers trek through lush forests, brimming with rhododendrons and oak, to reach its summit. Camping under starlit skies, they savor nature's beauty, creating unforgettable memories amidst the mountains.",
  },
];
export function Places() {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  // Define an array of places

  return (
    <>
    <div className="hidden lg:block">
     <div className="container mx-auto grid grid-cols-2 gap-8  ">
      {placesData
        .slice(0, showAll ? placesData.length : 6)
        .map((place, index) => (
  
          <Card key={index} className="w-full flex-row">
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={place.image}
                alt="card-image"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
              <Typography
                variant="h6"
                color="gray"
                className="mb-4 uppercase font-medium"
              >
                {place.distance}
              </Typography>
              <Typography
                variant="h5"
                color="blue-gray"
                className="mb-2 font-medium"
              >
                {place.name}
              </Typography>
              <Typography color="gray" className="mb-8 font-normal">
                {place.description}
              </Typography>
            </CardBody>
          </Card>
   
        ))}
      <div className="text-right col-span-2 mt-4 font-semibold">
        <Button className="bg-[#29343d]" onClick={toggleShowAll}>
          {showAll ? "View Less" : "View More"}
        </Button>
      </div>
    </div>
    </div>
    <div className="container mx-auto  grid-cols-1 block lg:hidden ">
      {placesData
        .slice(0, showAll ? placesData.length : 6)
        .map((place, index) => (
          <div className="mt-3">
          <Card key={index} className="w-full flex-row">
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={place.image}
                alt="card-image"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
              <Typography
                variant="h6"
                color="gray"
                className="mb-4 uppercase font-medium"
              >
                {place.distance}
              </Typography>
              <Typography
                variant="h5"
                color="blue-gray"
                className="mb-2 font-medium"
              >
                {place.name}
              </Typography>
              <Typography color="gray" className="mb-8 font-normal">
                {place.description}
              </Typography>
            </CardBody>
          </Card>
          </div>
        ))}
      <div className="text-right col-span-2 mt-4 font-semibold">
        <Button className="bg-[#29343d]" onClick={toggleShowAll}>
          {showAll ? "View Less" : "View More"}
        </Button>
      </div>
    </div>
    </>
  );
}
