import React, { useEffect, useState } from "react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";


function Testimonial() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const screenSize = window.innerWidth;
  const cardsPerPage = screenSize>720?3:2;
  const cards = [
    {
      category:
        "I went there with my friends excelent hospitality .special thanks to mr vishal .He is chef in hotel he make home made chicken for us delicias👌👌thanks to all hotel stall who make our memorable stay.Thanks to all of you",
      logo: "/assets/images/common/quotes.svg",
      logo2:
        "https://tailus.io/sources/blocks/grid-cards/preview/images/avatars/second_user.webp",
      description: "Pankaj Nathan",
    },
    
    {
        description: "Vishwa V",
      category:
        "We were the first travellers to this property and can’t deny about how beautiful the place is. The property is located at an amazing location which has the best view of Dharamshala. The staff was so helpful and friendly that it felt like home. If you ever visit Dharamshala, you are on the right page. You should definitely go for this property and nothing else.",
      logo: "/assets/images/common/quotes.svg",
      logo2:
        "https://tailus.io/sources/blocks/grid-cards/preview/images/avatars/second_user.webp",
    },
    {
        description: "Amit Basant",
      category:
        "What can I say...awesome. Visit the place for its beauty. The hotel is clean, staff helpful and the location is beautiful and convenient. What else can you ask for when you are out for a vacation :)",
      logo: "/assets/images/common/quotes.svg",
      logo2:
        "https://tailus.io/sources/blocks/grid-cards/preview/images/avatars/second_user.webp",
    },
    {
        description: "Shalini Chawla",
      category:
        "The extremely warm hosts and a feeling of home provided with everything accessible. The views from this place are absolutely amazing and breathtakingly beautiful.❤️❤️",
      logo: "/assets/images/common/quotes.svg",
      logo2:
        "https://tailus.io/sources/blocks/grid-cards/preview/images/avatars/second_user.webp",
    },
    {
        description: "Sameer Mahajan",
        category:
          "Great views, excellent food and hospitality. Away from the main town and therefore quite and peaceful place to stay.",
        logo: "/assets/images/common/quotes.svg",
        logo2:
          "https://tailus.io/sources/blocks/grid-cards/preview/images/avatars/second_user.webp",
      },
      {
        description:"Arun Sharma",
        category:"Best hospitality with cooperative staff. Immaculate and spacious room with mountain views. It was amazing experience .Thanx to Sierra hotel",
        logo: "/assets/images/common/quotes.svg",
      },
      {
        description:"Priyanka Rajput",
        category:"Great location, Excellent view...The room were clean, very comfortable and the staff was amazing.",
        logo: "/assets/images/common/quotes.svg",
      } ,
      {
        description:"Prince Pathania",
        category:"New Hotel, comfortable though.Food amazing.good staff.Location and view is also good.",
        logo: "/assets/images/common/quotes.svg",
      } ,
      {
        description:"Gopal Sharma",
        category:"Good view and good rooms are available with all requirements for a perfect travel experience. Keep it up.",
        logo: "/assets/images/common/quotes.svg",
      } ,
      {
        description:"Sahil",
        category:"Rooms condition are very good. Good facilities available in hotel. Staff are very well. Best hotel in Dharamshala. Please visit",
        logo: "/assets/images/common/quotes.svg",
      } ,
      {
        description:"Ashish Thapa",
        category:"Wonderful Property. Nice Rooms and perfect for working Singles and Couples. Must Stay when in Dharamshala. Keep up the good work.",
        logo: "/assets/images/common/quotes.svg",
      } ,
  ];

  const totalPages = Math.ceil(cards.length / cardsPerPage);
  

  const handleClickNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + cardsPerPage >= cards.length
        ? 0
        : prevIndex + cardsPerPage
    );
  };

  const handleClickPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - cardsPerPage < 0
        ? (totalPages - 1) * cardsPerPage
        : prevIndex - cardsPerPage
    );
  };
  const iconSize=25
  useEffect(() => {
    const timer = setTimeout(handleClickPrev, 6000); // 6 seconds
    return () => clearTimeout(timer);
  }, [currentIndex]); 

  return (
    <div className="bg-[#F7F7FA]">
      <div className="p-3 lg:p-0 container mx-auto">
        <button
          className="px-2 rounded-full text-sm "
          style={{ backgroundColor: "#E0F8DE" }}
        >
          Reviews
        </button>
        <div className="flex justify-center mt-4 visible md:invisible">
          <button
            onClick={handleClickPrev}
            className="mr-2 px-3 py-1 border rounded-full text-sm"
          >
            <FaArrowAltCircleLeft size={iconSize}/>
          </button>
          <button
            onClick={handleClickNext}
            className="px-3 py-1 border rounded-full text-sm"
          >
            <FaArrowAltCircleRight size={iconSize}/>
          </button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-16">
  
          {cards
            .slice(currentIndex, currentIndex + cardsPerPage)
            .map((card, index) => (
              <div
                key={index}
                className="h-full bg-white-800 shadow-lg bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative"
              >
                <img
                  src={card.logo}
                  alt="Logo1"
                  className=" w-8 h-8 absolute top-6 left-6"
                />
                <p>{card.description}</p>
                <h2 className="text-sm  mt-4  title-font font-medium text-gray-500 ">
                  {card.category}
                </h2>
              </div>
            ))}
        </div>
        <div className="flex justify-center mt-4 invisible md:visible">
          <button
            onClick={handleClickPrev}
            className="mr-2 px-3 py-1 border rounded-full text-sm"
          >
            <FaArrowAltCircleLeft size={iconSize}/>
          </button>
          <button
            onClick={handleClickNext}
            className="px-3 py-1 border rounded-full text-sm"
          >
            <FaArrowAltCircleRight size={iconSize}/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
