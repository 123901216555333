import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
} from "@material-tailwind/react";

const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

const faqs = [
  {
    question: "What are the check-In and check-Out times at Sierra INN?",
    answer: "At Sierra INN, check-in is at 14:00 and check-out is at 12:00.",
  },
  {
    question: "What is the address of Sierra INN?",
    answer:
      "Sierra Inn, Village: Sokni da kot Khanyara Rd, Dharamshala, Himachal Pradesh Khanyara, Mohli, Himachal Pradesh 176218.",
  },
  {
    question: "Can I store my luggage at Sierra INN?",
    answer: "Yes, baggage storage is available at Sierra INN.",
  },
  {
    question: "Is parking available at Sierra INN?",
    answer: "Yes, on-site parking is available at Sierra INN.",
  },
  {
    question: "How many parking spots are available at Sierra INN?",
    answer: "At Sierra INN, 1 parking spot is available.",
  },
  {
    question: "Is breakfast served at Sierra INN?",
    answer: "Yes, breakfast is available at Sierra INN.",
  },
  {
    question: "What time is breakfast served at Sierra INN?",
    answer: "At Sierra INN, breakfast is served from 08:00 AM to 10:30 AM.",
  },
  {
    question: "What is the email address of Sierra INN?",
    answer:
      "The email address of Sierra INN is namanavalleydharamshala@gmail.com.",
  },
  {
    question: "What is the telephone number of Sierra INN?",
    answer: "The telephone number of Sierra INN Dharamshala is +91 8628897599.",
  },
];

export function AccordionFaqs() {
  const [open, setOpen] = React.useState(null);

  const handleOpen = (index) => setOpen(open === index ? null : index);

  return (
    <>
      {faqs.map((faq, index) => (
        <Accordion key={index} open={open === index} animate={CUSTOM_ANIMATION}>
          <AccordionHeader onClick={() => handleOpen(index)}>
            <Typography variant="h6" color="blue-gray" className="font-medium">
              {faq.question}
            </Typography>
          </AccordionHeader>
          <AccordionBody>{faq.answer}</AccordionBody>
        </Accordion>
      ))}
    </>
  );
}
