import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Avatar,
    Tooltip,
  } from "@material-tailwind/react";
   
  export function BlogCard({title,description,imageUrl}) {
    return (
      <Card className=" overflow-hidden">
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="m-0 rounded-none"
        >
          <img
            src={imageUrl}
          />
        </CardHeader>
        <CardBody>
          <Typography variant="h6" color="blue-gray font-normal">
            {title}
          </Typography>
          <Typography  color="gray" className="mt-2 font-normal">
            {description}
          </Typography>
        </CardBody>
  
      </Card>
    );
  }