import { Typography } from '@material-tailwind/react'
import React from 'react'

function Title({title,color,container}) {
  return (
    <div className="mb-6">
      <Typography
        color={color}
        className={`text-2xl lg:text-3xl   text-center lg:text-left tracking-wider mb-2 ${container&&"container mx-auto"}`}
      >
        {title}
      </Typography>

    </div>
  )
}

export default Title