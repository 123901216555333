import { IconButton, Typography } from "@material-tailwind/react";
import React from "react";

const options = [
  {
    name: "Arriving from Kangra-Gaggal Airport",
    medium: "Using a car or taxi",
    mediumImage:"/assets/images/transport/plane.png",
    description:
      "Kangra-Gaggal Airport is approximately 16 kilometers away from the hotel, and the journey typically takes around 30 minutes by car.",
  },
  {
    name: "Coming from Pathankot Railway Station",
    medium: "Traveling by car or taxi",
    mediumImage:"/assets/images/transport/train.png",
    description:
      "If you're arriving at Pathankot Railway Station, the hotel is conveniently accessible by car or taxi. It's a scenic 90-kilometer drive, and you can expect to reach the hotel in approximately 2.5 hours.",
  },
  {
    name: "Arriving at Dharamshala Bus Station",
    mediumImage:"/assets/images/transport/bus.png",
    medium: "Using a car or taxi",
    description:
      "For guests arriving at Dharamshala Bus Station, the hotel is just a 10-kilometer drive away. The journey usually takes between 15 – 20 minutes, offering a comfortable and convenient transportation option.",
  },
];

function GettingIn() {
  return (
    <div className="grid grid-cols-1 gap-6   ">

      <div className="container mx-auto">
        {options.map((item, index) => (
          <div className={`grid grid-cols-5 ${index>0&&"mt-5"} p-6 bg-white rounded-md `}>
            <div className="gird  col-span-12 md:col-span-2 flex  place-items-center">
              <div className="p-2 rounded-[50%] bg-gray-100 h-[70px] w-[70px]">
                <img  src={item.mediumImage} />
              </div>
              <Typography
                variant="h5"
                className=" ms-3 container mx-auto font-medium mb-2"
              >
                {item.name}
              </Typography>
            </div>
            <div className="gird  col-span-12 md:col-span-3">
              <Typography
                variant="h5"
                className=" container mx-auto font-normal mb-2"
              >
                {item.medium}
              </Typography>
              <Typography className=" container mx-auto font-normal mb-2">
                {item.description}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GettingIn;
